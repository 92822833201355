<template>
  <v-card class="mx-auto" :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color="grey darken-3"
            size="x-small"
            :to="{
              name: `${page_route}${store_mode ? '' : '.detalle'}`,
              params: { id: id },
            }"
          />
          <span class="text-h6 ml-1" v-text="$route.meta.title" />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text v-if="data">
      <v-form ref="form_data" lazy-validation>
        <v-row>
          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-caption" v-text="'GENERAL'" />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Nombre*"
                      v-model="data.contractor_brand"
                      maxlength="50"
                      type="text"
                      counter
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-autocomplete
                      label="Día de pago"
                      v-model="data.pay_day"
                      :items="pay_days"
                      item-value="id"
                      :item-text="(v) => v.name"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      :label="'Generar factura'"
                      v-model="data.bill_generated"
                      class="mt-0"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      :label="'Agrupar facturas'"
                      v-model="data.bill_group"
                      class="mt-0"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      :label="'Suma D. y C. para total_amount'"
                      v-model="data.calculate_amount"
                      class="mt-0"
                      dense
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-text-field
                      label="Número de cuenta"
                      v-model="data.account_number"
                      type="text"
                      dense
                      counter
                      maxlength="18"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" md="3">
                    <v-switch
                      :label="'Consolidado'"
                      v-model="data.consolidated"
                      class="mt-0"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card class="mx-auto">
              <v-card-title>
                <h2
                  class="text-caption"
                  v-text="'REPORTE SERVICIOS LÍNEA 12'"
                />
              </v-card-title>
              <v-divider />
              <v-card-text>
                <v-row
                  dense
                  v-for="(
                    contractor_brand_rs_type, i
                  ) in data.contractor_brand_rs_types"
                  :key="i"
                  v-if="contractor_brand_rs_type.active"
                >
                  <v-col cols="12" xs="12" md="10">
                    <v-autocomplete
                      label="Tipo de servicio"
                      v-model="contractor_brand_rs_type.rs_type_id"
                      :items="rs_types"
                      :loading="rs_types_loading"
                      item-value="id"
                      :item-text="(v) => v.type"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    xs="12"
                    md="2"
                    class="text-center"
                    align-self="center"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          text
                          icon
                          x-small
                          color="error"
                          @click="rsTypeRemove(i)"
                        >
                          <v-icon medium> mdi-close </v-icon>
                        </v-btn>
                      </template>
                      <span v-text="`Eliminar tipo de servicio (${i + 1})`" />
                    </v-tooltip>
                  </v-col>
                  <v-col cols="12">
                    <v-divider />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-btn @click="rsTypeAdd" color="warning" x-small block dark>
                    <v-icon left> mdi-plus </v-icon>
                    Agregar
                  </v-btn>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" class="text-right">
            <BtnCircle
              :tLabel="store_mode ? 'Crear' : 'Editar'"
              tPosition="top"
              :icon="store_mode ? 'mdi-plus' : 'mdi-pencil'"
              :color="store_mode ? 'success' : 'info'"
              size="small"
              :click="handleSubmit"
              :loading="loading"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
} from "../../control";
import { storeUpdate, show } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    FaqDlg,
  },
  data() {
    return {
      page_route: "contratantes.concentradores",
      id: this.$route.params.id ? this.$route.params.id : 0,
      store_mode: this.$route.params.id ? false : true,
      login: this.$store.getters.getLogin,
      loading: false,
      data: {
        id: null,
        contractor_brand: "",
        bill_generated: false,
        pay_day: 0,
        bill_group: false,
        calculate_amount: false,
        contractor_brand_rs_types: [],
        account_number: "",
        consolidated: false,
      },
      rules: rules(),
      pay_days: [
        {
          id: 1,
          name: "LUNES",
        },
        {
          id: 2,
          name: "MARTES",
        },
        {
          id: 3,
          name: "MIERCOLES",
        },
        {
          id: 4,
          name: "JUEVES",
        },
        {
          id: 5,
          name: "VIERNES",
        },
        {
          id: 0,
          name: "NINGUNO",
        },
      ],
      rs_types: [],
      rs_types_loading: true,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form_data.validate()) {
        this.$swal
          .fire(
            msgConfirm(
              `¿Confirma la ${
                this.store_mode ? "creación" : "edición"
              } del registro?`
            )
          )
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;

              storeUpdate(
                "contractors/brands",
                this.login.token,
                this.id,
                this.data,
                this.login.id
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({
                      name: `${this.page_route}${
                        this.store_mode ? "" : ".detalle"
                      }`,
                      params: { id: this.id },
                    })
                  : console.log(response.message);
              });

              this.loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    rsTypeAdd() {
      this.data.contractor_brand_rs_types.push({
        id: null,
        active: 1,
        rs_type_id: null,
      });
    },
    rsTypeRemove(i) {
      this.$swal
        .fire(msgConfirm(`¿Confirma eliminar el tipo de servicio (${i + 1})?`))
        .then((response) => {
          if (response.isConfirmed) {
            if (this.data.contractor_brand_rs_types[i].id === null)
              this.data.contractor_brand_rs_types.splice(i, 1);
            else this.data.contractor_brand_rs_types[i].active = false;
          }
        });
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    Axios.get(
      `${URL_API}/catalog?name=rs_types&no_order=false`,
      headersToken(this.login.token)
    ).then((resp) => {
      this.rs_types = resp.data.data;
      this.rs_types_loading = false;
    });

    if (!this.store_mode) {
      this.loading = true;
      show("contractors/brands", this.login.token, this.id).then((response) => {
        this.data = response;
        this.loading = false;
      });
    }
  },
};
</script>